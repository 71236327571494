<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          ตั้งค่าหน้า
          <ion-text :color="modeMeta.color">
            {{ modeMeta.label }}
          </ion-text>
        </ion-title>
        <ion-buttons slot="start">
          <ion-button @click="back">
            <ion-icon :ios="icons.backOutline" :md="icons.backSharp"></ion-icon>
            <ion-label>
              {{ changed ? "ยกเลิก" : "Back" }}
            </ion-label>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button v-if="!isSaveButtonDisabled" @click="save" :disabled="isSaveButtonDisabled">
            <ion-label>บันทึก</ion-label>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen>
      <ion-list>
        <ion-item-divider>
          {{ modeMeta.acc1Label }}
          <ion-button slot="end" color="secondary" @click="openPicker(1)">
            <ion-label>เลือก</ion-label>
          </ion-button>
        </ion-item-divider>
        <ion-reorder-group :disabled="false" @ionItemReorder="doReorderAcc1($event)">
          <ion-item v-for="account in acc1DisplayAccounts" :key="account.account_id">
            <ion-label>{{ account.label }}</ion-label>
            <ion-reorder slot="end"></ion-reorder>
          </ion-item>
        </ion-reorder-group>
        <ion-item-divider>
          {{ modeMeta.acc2Label }}
          <ion-button slot="end" color="secondary" @click="openPicker(2)">
            <ion-label>เลือก</ion-label>
          </ion-button>
        </ion-item-divider>
        <ion-reorder-group :disabled="false" @ionItemReorder="doReorderAcc2($event)">
          <ion-item v-for="account in acc2DisplayAccounts" :key="account.account_id">
            <ion-label>{{ account.label }}</ion-label>
            <ion-reorder slot="end"></ion-reorder>
          </ion-item>
        </ion-reorder-group>
      </ion-list>
    </ion-content>

  </ion-page>
</template>

<script>
import {
  modalController,
  // ionic components
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonReorder,
  IonReorderGroup,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { chevronBackOutline, chevronBackSharp, informationCircleOutline, informationCircleSharp, saveOutline, saveSharp } from "ionicons/icons";
import { inputModes } from "../../../helper.js";
import ModalMultipleAccountsPicker from "../../components/ModalMultipleAccountsPicker.vue";
import { mapActions, mapGetters } from "vuex";
import { inject } from "vue";
export default {
  name: "Form",
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonPage,
    IonReorder,
    IonReorderGroup,
    IonText,
    IonTitle,
    IonToolbar,
  },
  props: {
    mode: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      changed: false,
      acc1selected: [],
      acc2selected: [],
    };
  },
  computed: {
    ...mapGetters({
      save_id: "save/currentSaveId",
      currentSavePreferences: "save/currentSavePreferences",
      currentFavAcc: "save/currentSavePrefFavAcc",
      assets: "account/currentSaveAssets",
      categories: "account/currentSaveCategories",
      hashedAccounts: "account/hashedAccounts",
    }),
    icons() {
      return {
        backOutline: chevronBackOutline,
        backSharp: chevronBackSharp,
        infoOutline: informationCircleOutline,
        infoSharp: informationCircleSharp,
        saveOutline,
        saveSharp,
      };
    },
    modes() {
      return inputModes();
    },
    modeMeta() {
      for (const item of this.modes) {
        if (item.mode === this.mode) {
          return item;
        }
      }
      return false;
    },
    acc1DisplayAccounts() {
      return this.displayAccountsFor(1);
    },
    acc2DisplayAccounts() {
      return this.displayAccountsFor(2);
    },
    isSaveButtonDisabled() {
      return !this.changed;
    },
  },
  methods: {
    ...mapActions({
      updateSaveFavAcc: "save/updateSaveFavAcc",
    }),
    async doReorderAcc1(event) {
      return await this.doReorder(event, 1);
    },
    async doReorderAcc2(event) {
      return await this.doReorder(event, 2);
    },
    async doReorder(event, accountNumber) {
      const tmp = this[`acc${accountNumber}selected`][event.detail.from];
      this[`acc${accountNumber}selected`][event.detail.from] = this[`acc${accountNumber}selected`][event.detail.to];
      this[`acc${accountNumber}selected`][event.detail.to] = tmp;
      this.changed = true;

      return await event.detail.complete();
    },
    async openMultipleAccountPicker({ accounts, selectedItems, categoryMode, onReceivedData }) {
      const modal = await modalController.create({
        component: ModalMultipleAccountsPicker,
        componentProps: {
          accounts,
          selectedItems,
          categoryMode,
          maxItems: 5,
        },
        swipeToClose: false,
      });

      modal.onWillDismiss().then((recv) => {
        if (recv.data && Array.isArray(recv.data)) {
          if (onReceivedData) {
            onReceivedData(recv.data);
          }
        }
      });

      return modal.present();
    },
    async openPicker(accountNumber) {
      const accounts = this.modeMeta[`acc${accountNumber}Type`] === "asset"
        ? this.assets
        : this.categories;

      const selectedItems = [...this[`acc${accountNumber}selected`]];
      const categoryMode = this.modeMeta[`acc${accountNumber}Type`] === "category";

      await this.openMultipleAccountPicker({
        accounts,
        selectedItems,
        categoryMode,
        onReceivedData: (selected) => {
          this[`acc${accountNumber}selected`] = [...selected];
          this.changed = true;
        },
      });
    },
    displayAccountsFor(accountNumber) {
      const items = [];

      for (const id of this[`acc${accountNumber}selected`]) {
        items.push({
          account_id: id,
          label:
            this.modeMeta[`acc${accountNumber}Type`] === "category"
              ? this.hashedAccounts[id].parentString + this.hashedAccounts[id].account_name
              : this.hashedAccounts[id].account_name,
        });
      }

      return items;
    },
    async save() {
      if (this.isSaveButtonDisabled)
        return;

      const favAcc = { ...this.currentFavAcc };

      favAcc[this.modeMeta.acc1FavAcc] = this.acc1selected;
      favAcc[this.modeMeta.acc2FavAcc] = this.acc2selected;

      await this.updateSaveFavAcc({
        save_id: this.save_id,
        favAcc,
      });

      this.back();
    },
  },
  mounted() {
    if (this.modeMeta === false) {
      // ถ้าไม่ใช่ 3 mode ที่รองรับ ให้วิ่งกลับไปเมนู fav accounts
      this.$router.push({ name: "settings-favoriteAccounts" });
    } else {
      // อ่านค่าจาก save preferences เอามาระบุ local data
      if (this.currentFavAcc[this.modeMeta.acc1FavAcc]) {
        this.acc1selected = [...this.currentFavAcc[this.modeMeta.acc1FavAcc]];
      }
      if (this.currentFavAcc[this.modeMeta.acc2FavAcc]) {
        this.acc2selected = [...this.currentFavAcc[this.modeMeta.acc2FavAcc]];
      }
    }
  },
  setup() {
    const ionRouter = inject("navManager");
    const back = () => {
      ionRouter.navigate({
        routerDirection: "back",
        routerLink: { name: "settings-favoriteAccounts"},
      });
    };
    return {
      // methods
      back
    };
  },
};
</script>

<style scoped>
ion-text.small {
  font-size: 80%;
}
</style>
